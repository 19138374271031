<template>
  <ion-card
    class="ml-0 mt-0 mb-2 raffle-card clickable-item-hov p-0"
    :class="{
      'border-success': entryStatus,
      'card-height': get(raffle, 'status') === 'ended',
      'no-mob-margin': hideMobileMargin,
      'border-dark': isFeatured && !entryStatus,
    }"
    :style="raffle.is_new ? 'border: 3px solid red !important' : ''"
  >
    <router-link :to="{ name: 'raffle-details', params: { slug: raffle.slug } }">
      <div>
        <div class="one-raffle d-flex flex-column h-100">
          <div class="h-100">
            <div id="buttons" class="mt-2 badge-container">
              <div class="badge-row flex-wrap">
                <ion-badge class="badge">
                  <div class="description m-auto">
                    {{
                      raffle.category === 'other'
                        ? truncateBadgeText(stripHtmlTags(raffle.other_category))
                        : categoriesByVal[raffle.category].name
                    }}
                  </div>
                </ion-badge>
                <ion-badge v-if="entryStatus" class="entry-badge description">Entered</ion-badge>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <img loading="lazy" v-image :src="raffle.visuals[0]" />
            </div>
          </div>
          <div
            class="name d-flex mt-2 px-2 text-black justify-content-center align-items-center text-center clickable-item h-100"
          >
            {{ truncateTitleText(stripHtmlTags(raffle.title)) }}
          </div>

          <div class="d-flex text-black align-items-center flex-column mt-auto mx-3 mb-2">
            <div
              class="text-primary d-flex align-items-center text-center"
              style="font-size: 12px; font-weight: bold"
              @click.stop
            >
              <router-link
                class="d-flex"
                :class="{
                  'text-color-username':
                    usernameColor(get(raffle, 'user')) && !usernameColor(get(raffle, 'user')).startsWith('#'),
                  'bold-username': usernameColor(get(raffle, 'user')),
                }"
                :style="
                  !usernameColor(get(raffle, 'user'))
                    ? ''
                    : usernameColor(get(raffle, 'user')).startsWith('#')
                    ? `color: ${usernameColor(get(raffle, 'user'))} !important`
                    : `background-image: ${usernameColor(get(raffle, 'user'))} !important`
                "
                :to="{ name: 'profile', params: { username: get(raffle, 'user.username') } }"
              >
                <span class="mr-1"> @{{ get(raffle, 'user.username') }}</span>
                <ProfileBadge :badges="get(raffle, 'user.customize_profile.badges') || []" :show-small-badge="true" />
              </router-link>
            </div>

            <ion-button v-if="get(raffle, 'status') !== 'ended'" class="end-btn mt-2">
              <i class="ti-timer mx-1" />
              Ends in

              <div class="mx-1">
                <vue-countdown
                  v-if="raffle?.start_date"
                  v-slot="{ days, hours, minutes }"
                  :time="countdownTime(raffle)"
                  class="d-flex"
                  style="gap: 3px"
                >
                  <div>{{ days }}d</div>

                  <div>{{ leadingZero(hours) }}h</div>

                  <div>{{ leadingZero(minutes) }}m</div>
                </vue-countdown>
              </div>
            </ion-button>
          </div>
        </div>
      </div>
    </router-link>
  </ion-card>
</template>

<script lang="ts" setup>
import { stripHtmlTags, truncateText } from '@/shared/utils/string';
import constants from '@/shared/statics/constants';
import { leadingZero } from '@/shared/utils/number';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { authStore } from '@/shared/pinia-store/auth';

const props = defineProps({
  raffle: { type: Object, default: {} },
  entryStatus: { type: Boolean, default: false },
  hideMobileMargin: { type: Boolean, default: false },
  isFeatured: { type: Boolean, default: false },
});

const { user: loggedInUser } = authStore();

const categoriesByVal = keyBy(constants.raffleCategories, 'value');
const raffle: any = toRef(props, 'raffle');
const isFeatured: any = toRef(props, 'isFeatured');
const badges = computed(() => {
  return get(loggedInUser.value.customize_profile, 'badges') || [];
});
const usernameColor = (user: any) => {
  return user?.customize_profile?.username_color || '';
};

const getBadgeColor = computed(() => {
  return categoriesByVal[raffle.value.category].color;
});

const truncateBadgeText = (value: string, length = 25) => {
  return truncate(value, { length, separator: /,? +/ });
};
const truncateTitleText = (value: string, length = 20) => {
  return truncate(value, { length, separator: /,? +/ });
};

const countdownTime = (value: any) => {
  const durationInMilliseconds = value.duration * 24 * 60 * 60 * 1000;
  const startTime = new Date(get(value, 'start_date')).getTime();
  const endTime = startTime + durationInMilliseconds;
  return endTime - Date.now();
};

const desktopCardWidth = computed(() => {
  return isFeatured.value ? 'calc(50% - 10px)' : 'calc(33% - 9px)';
});
</script>

<style lang="sass" scoped>

.description
  font-size: 10px

.card-height
  min-height: 228px !important

.border-success
  border: 2px solid #43b701
  img
    border-top-left-radius: 8px !important
    border-top-right-radius: 8px !important

.border-dark
  border: 2px solid #7a7e9b
  img
    border-top-left-radius: 8px !important
    border-top-right-radius: 8px !important

.dark .border-dark
  border: 2px solid #ffffff !important

.badge
  display: flex
  align-items: center
  height: 20px
  justify-content: center
  width: auto
  max-width: 100%
  min-width: 68px

  border-radius: 20px
  background: #09183c
  color: v-bind(getBadgeColor)
  border: 2px solid v-bind(getBadgeColor)

.entry-badge
  display: flex
  align-items: center
  justify-content: center
  width: auto
  max-width: 100%
  min-width: 68px
  height: 20px

  border-radius: 20px
  background: #8ce53b
  color: black
.hide
  pointer-events: none
  opacity: 0

.name
  font-size: 13px
  font-weight: bold
  color: #000
  cursor: pointer
  word-break: break-word
  margin: auto 0.25rem
  .name:hover
    opacity: 0.7
.see-all-text
  color: #214163
  display: inline-block
.badge-row
  display: flex
  width: 100%
  gap: 2px

.badge-container
  display: flex
  left: 4px
  top: 0px
  position: absolute
  flex-direction: column
  align-items: center
  max-width: 400px
.end-btn
  height: 20px
  --border-radius: 20px
  text-transform: none
  font-size: 11px !important

.title
  font-size: 36px
  font-weight: bold
  margin-bottom: 12px
  margin-top: 30px
.raffle-card
  overflow: unset !important
  min-width: 160px !important
  text-break: break-word
  min-height: 200px
  border-radius: 12px

  @media (min-width: 768px)
    width: v-bind(desktopCardWidth) !important

  @media (min-width: 576px)
    width: calc(50% - 10px)

  @media (max-width: 575px)
    // margin-right: 0px
    width: 100%
    min-width: 200px !important
    img
      // height: auto !important
      object-fit: cover

  img
    border-top-left-radius: 10px
    border-top-right-radius: 10px

  .icon-button
    color: #ae38e5
    font-size: 20px
    .d-flex
      font-size: 20px

.one-raffle
  img
    width: 100%
    height: 90px
    object-fit: cover

.no-mob-margin
  @media(max-width: 575px)
    margin-right: 0px
</style>
